import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {Helmet} from "react-helmet";
import AdSense from 'react-adsense';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
      </Helmet>
      <div className="blog-post-container" style={{ width: '650px', float: 'left' }}>
        <div className="blog-post" >
          <h1>{frontmatter.title}</h1>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <AdSense.Google
            client='ca-pub-1224825058047044'
            slot='7397615898'
          />
        </div>
      </div>
      <div style={{ backgroundColor: 'lightgrey', marginLeft: '670px' }}>
        <div style={{ width: '160px',
                      height: '600px',
                      display: 'block',
                       }}></div>
      </div>
      <div style={{ clear: 'both' }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
