import React from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";

import "../../css/style.css"
// UA-101622521-4

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

//       <script dangerouslySetInnerHTML={{__html: adsense_script}}></script>

                        
export default ({ children }) => {
  const adsense_script = `(adsbygoogle = window.adsbygoogle || []).push({
                          google_ad_client: "ca-pub-1224825058047044",
                          enable_page_level_ads: true
                          });`
  return (
  <div id="container" style={{ margin: `0 auto`, maxWidth: 950, padding: `1.25rem 1rem` }}>
    <Helmet>
      <meta name="google-site-verification" content="_29mMTh1cyO0T8E_o-LyhBcUqo0X8AWT77WwqXWH7Uk" />
      <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    </Helmet>
    <header style={{ marginBottom: `1.5rem` }}>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }} id="logo">
        <h3 style={{ display: `inline` }}>supplementDB <span style={{ fontSize: '14px' }}>.org</span></h3>
      </Link>
      
      <ul style={{ listStyle: `none`, float: `right` }}>
        <Link to="/search/">Search Supplements</Link>
        <Link to="/news/">Health News</Link>
        <Link to="/RU58841/">RU58841</Link>
        <Link to="/">Home</Link>
      </ul>
      
    </header>
    {children}
  </div>
  )
}